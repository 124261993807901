<template>
  <v-container fluid>
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-show="loading"
    ></v-progress-linear>
    <v-card raised>
      <v-flex>
        <v-card-title>
          <v-btn fab flat @click.native="goback">
            <v-icon class="pointer" v-ripple>arrow_back</v-icon>
          </v-btn>
          <div class="headline">Add Roles and Sub Roles</div>
        </v-card-title>
      </v-flex>
      <v-form ref="form">
        <v-container
          bg
          fill-height
          grid-list-md
          text-xs-center
          class="title-layout-size"
        >
          <v-layout row wrap align-center>
            <v-flex md6 class="payadvance">
              <v-card-title class="bg-clr title-layout-size">
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="selectRole"
                  row
                  :mandatory="true"
                  @change="clear()"
                  :rules="[rules.required]"
                >
                  <div>
                    <div class>
                      <v-layout>
                        <v-radio
                          label="Add Role"
                          value="role"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Add Sub Role"
                          value="subRole"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Add Role and Sub Role"
                          value="both"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-card-title>

              <v-card-text class="title-layout-size">
                <v-layout row wrap>
                  <v-flex v-if="selectRole == 'role' || selectRole == 'both'">
                    <v-text-field
                      v-model="role"
                      :rules="[rules.required]"
                      label="Role"
                    ></v-text-field>
                  </v-flex>
                  <v-flex v-if="selectRole == 'subRole'">
                    <v-autocomplete
                      v-model="select"
                      :items="rolesType"
                      label="Select Role"
                      item-text="roleTitle"
                      item-value="_id"
                      return-object
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex
                    v-if="selectRole == 'subRole' || selectRole == 'both'"
                  >
                    <v-text-field
                      v-model="subrole"
                      :rules="[rules.required]"
                      label="Sub Role"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container
          bg
          fill-height
          grid-list-md
          text-xs-center
          class="title-layout-size"
        >
          <v-layout row wrap align-center>
            <v-flex
              md5
              class="payadvance"
              v-if="this.selectRole == 'role' || this.selectRole == 'both'"
            >
              <v-btn
                color="orange darken-1"
                style="color: white"
                @click.prevent="addRoles()"
                :loading="loading"
                >Submit</v-btn
              >
            </v-flex>
            <v-flex md5 class="payadvance" v-if="this.selectRole == 'subRole'">
              <v-btn
                color="orange darken-1"
                style="color: white"
                @click.prevent="addSubrole()"
                :loading="loading"
                >Submit</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <!-- <ErrorBox :error="x.error" /> -->
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-card>
  </v-container>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import Swal from "sweetalert2";
export default {
  created() {
    mixins: [checkPermissionsMixin], this.getRoles();
    // this.reqType.name = this.$route.params.reqtype;
    // this.reqType.title = this.checkTitle(this.reqType.name);
  },

  components: {
    ErrorBox,
    SuccessDialog,
  },
  data() {
    return {
      data: "",
      reqType: {
        name: "",
        title: "",
      },
      subrole: "",
      role: "",
      selectRole: "role",
      loading: false,
      select: null,
      rolesType: [],
      processing: false,
      x: {
        message: "Added Successfully",
        success: false,
        error: null,
      },
      types: [
        {
          name: "User Type",
          reqAbbr: "userType",
          abbr: "user-type",
          req: "createUserType",
          body: { userType: "" },
        },
        {
          name: "Role",
          reqAbbr: "roleName",
          abbr: "role",
          req: "createRole",
          body: { roleName: "" },
        },
        {
          name: "Sub Role",
          reqAbbr: "operationTitle",
          abbr: "sub-role",
          req: "createOperation",
          body: { operationName: "", operationTitle: "" },
        },
      ],

      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  methods: {
    clear() {
      this.subrole = "";
      this.role = "";
      this.select = null;
      this.$refs.form.resetValidation();
    },
    checkPermission() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());

          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());

          old.sort();
          roles.sort();

          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            // this.add();
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    checkTitle(reqType) {
      let title = "";

      this.types.forEach((value) => {
        if (value.abbr == reqType) {
          title = value.name;
        }
      });
      return title;
    },

    closeMessage() {
      this.x.success = false;
      this.$router.push("/role-management");
    },
    goback() {
      this.$router.push("/role-management");
    },
    addRoles() {
      this.loading = true;

      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let url = `/api/role`;

        let obj = { roleTitle: this.role };
        if (this.selectRole == "both") {
          obj.operationTitle = this.subrole;
        }

        let token = localStorage.getItem(StorageKeys.SessionKey);

        delete this.axios.defaults.headers.common["token"];

        this.axios.put(this.constants.rbacUrl + url, obj).then(
          (response) => {
            if (!response.data.success) {
              this.x.error = response.data.message;
              this.loading = false;
              this.processing = false;
              return;
            } else {
              this.loading = false;
              this.processing = false;
              this.x.success = true;
              // this.rolesType = response.data.data.filter((x) => {
              //   return x;
              // });
            }
          },
          (error) => {
            this.loading = false;
            this.processing = false;
            this.x.success = false;
            this.x.error = error.response.data.message;
          }
        );
      }
    },
    addSubrole() {
      //this.checkPermission();
      this.loading = true;
      let reqType = this.$route.params.reqtype;

      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let url = `/api/operation`;
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let obj = {
          operationTitle: this.subrole,
          roleId: this.select._id,
        };

        delete this.axios.defaults.headers.common["token"];

        this.axios.put(this.constants.rbacUrl + url, obj).then(
          (response) => {
            if (!response.data.success) {
              this.x.error = response.data.message;
              this.loading = false;
              this.processing = false;
              return;
            } else {
              this.loading = false;
              this.x.success = true;
              this.processing = false;
            }
          },
          (error) => {
            this.loading = false;
            this.processing = false;
            this.x.error = error.response.data.message;
          }
        );
      }
    },

    getRoles() {
      this.processing = true;
      let url = `/api/role`;
      let token = localStorage.getItem(StorageKeys.SessionKey);

      delete this.axios.defaults.headers.common["token"];

      this.axios.post(this.constants.rbacUrl + url).then(
        (response) => {
          this.loading = false;
          this.processing = false;
          this.rolesType = response.data.data.filter((x) => {
            return x;
          });
        },
        (error) => {
          this.loading = false;
          this.processing = false;
          this.x.error = error.response.data.message;
        }
      );
    },
  },
};
</script>
<style scoped>
.payadvance {
  margin: 0 auto;
}
.title-layout-size {
  padding: 16px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
</style>
