<template>
  <v-container fluid>
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-show="loading"
    ></v-progress-linear>
    <v-card raised>
      <v-flex>
        <v-card-title>
          <v-btn fab flat @click.native="goback">
            <v-icon class="pointer" v-ripple>arrow_back</v-icon>
          </v-btn>
          <div class="headline">Add {{ this.reqType.title }}</div>
        </v-card-title>
      </v-flex>
      <v-form ref="form">
        <v-container
          bg
          fill-height
          grid-list-md
          text-xs-center
          class="title-layout-size"
        >
          <v-layout row wrap align-center>
            <v-flex md5 class="payadvance">
              <v-card-title class="bg-clr title-layout-size">
                <div class="title">{{ this.reqType.title }} Name:</div>
              </v-card-title>
              <v-card-text class="title-layout-size">
                <v-text-field
                  v-model="name"
                  :rules="[rules.required]"
                  counter
                  maxlength="100"
                ></v-text-field>
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container
          bg
          fill-height
          grid-list-md
          text-xs-center
          class="title-layout-size"
        >
          <v-layout row wrap align-center>
            <v-flex md5 class="payadvance">
              <v-btn
                color="orange darken-1"
                style="color: white"
                @click.prevent="checkPermission()"
                :loading="loading"
                >Submit</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <!-- <ErrorBox :error="x.error" /> -->
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-card>
  </v-container>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import Swal from "sweetalert2";
export default {
  created() {
    this.reqType.name = this.$route.params.reqtype;
    this.reqType.title = this.checkTitle(this.reqType.name);
  },

  components: {
    ErrorBox,
    SuccessDialog,
  },
  data() {
    return {
      reqType: {
        name: "",
        title: "",
      },
      name: "",
      loading: false,
      processing: false,
      x: {
        message: null,
        success: false,
        error: null,
      },
      types: [
        {
          name: "User Type",
          reqAbbr: "userType",
          abbr: "user-type",
          req: "createUserType",
          body: { userType: "" },
        },
        {
          name: "Role",
          reqAbbr: "roleName",
          abbr: "role",
          req: "createRole",
          body: { roleName: "" },
        },
        {
          name: "Sub Role",
          reqAbbr: "operationTitle",
          abbr: "sub-role",
          req: "createOperation",
          body: { operationName: "", operationTitle: "" },
        },
      ],

      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  methods: {
    checkPermission() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());

          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());

          old.sort();
          roles.sort();

          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            this.add();
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    checkTitle(reqType) {
      let title = "";

      this.types.forEach((value) => {
        if (value.abbr == reqType) {
          title = value.name;
        }
      });
      return title;
    },
    getRequest(reqType) {
      let request = "";

      this.types.forEach((value) => {
        if (value.abbr == reqType) {
          request = value.req;
        }
      });
      return request;
    },
    abbr(reqType) {
      let requestAbbr = "";

      this.types.forEach((value) => {
        if (value.abbr == reqType) {
          requestAbbr = value.reqAbbr;
        }
      });
      return requestAbbr;
    },
    getBody(reqType) {
      let body = "";
      this.types.forEach((value) => {
        if (value.abbr == reqType) {
          body = value.body;
        }
      });
      return body;
    },
    convertTitleToName(title) {
      let s = title.replace(" ", "-");
      s = s.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());

      return s;
    },
    closeMessage() {
      this.x.success = false;
      this.$router.push("/role-management");
    },
    goback() {
      this.$router.push("/role-management");
    },
    add() {
      let roles = JSON.parse(localStorage.getItem("permissions")).roles;
      roles = roles.map((x) => x.role.roleName.toLowerCase());

      if (!roles.includes("role-management")) {
        Swal.fire({
          title: "Oops!",
          text: "Your permissions have changed .",
          type: "error",
          allowOutsideClick: false,
          confirmButtonColor: "orange",
          confirmButtonText: "Okay",
        }).then((result) => {
          //   localStorage.setItem("first-load", true);
          //   this.$router.replace("/");
          //   location.reload();
        });
      } else {
        //this.checkPermission();
        this.loading = true;
        let reqType = this.$route.params.reqtype;
        if (!this.$route.params.reqtype || !this.name.trim()) {
          this.x.error = "Please fill all Fields";
          this.loading = false;
        } else {
          if (this.processing) {
            return;
          } else {
            this.processing = true;
            let url = "/" + this.getRequest(reqType);
            delete this.axios.defaults.headers.common["token"];

            let body = this.getBody(reqType);
            let reqAbbr = this.abbr(reqType);
            if (reqType == "user-type") {
              body.weight = 10;
            }
            body[reqAbbr] = this.name;
            body.allowDelete = false;
            body.isActive = true;
            body.isBlocked = false;
            body.isDeleted = false;

            if (reqAbbr == "operationTitle") {
              body.operationName = this.name.trim();
            }
            this.axios.post(this.constants.rbacUrl + url, body).then(
              (response) => {
                this.loading = false;
                this.processing = false;
                this.x.success = true;
                this.x.message = `${this.checkTitle(
                  reqType
                )} added Successfully.`;
              },
              (error) => {
                this.loading = false;
                this.processing = false;
                this.x.error = error.response.data.message;
              }
            );
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.payadvance {
  margin: 0 auto;
}
.title-layout-size {
  padding: 16px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
</style>
