<template>
  <v-card raised v-if="showScreen">
    <v-dialog v-if="dialog" max-width="400" v-model="mappingData">
      <v-card>
        <v-card-title class="headline">
          <h4>View Roles</h4>
        </v-card-title>
        <v-card-text class="scroll_class">
          <div v-if="mappingData.mappingType == 'user-role'">
            <div v-if="mappingData.roles && mappingData.roles.length > 0">
              <div :key="i" v-for="(role, i) in mappingData.roles">
                {{ i + 1 }}.
                <span class="role">{{ role.role.roleTitle }}</span>
              </div>
            </div>
            <div v-else>No Roles Found</div>
          </div>
          <div v-if="mappingData.mappingType == 'role-sub-role'">
            <div v-if="mappingData.subRole && mappingData.subRole.length > 0">
              <div :key="i" v-for="(subRole, i) in mappingData.subRole">
                {{ i + 1 }}.
                <span class="role">{{ subRole.operationTitle }}</span>
              </div>
            </div>
            <div v-else>No Sub Role Found</div>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="orange darken-1"
            class="px-2 white--text"
            text
            @click="dialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-linear
      indeterminate
      color="trukkin-theme darken-2"
      v-show="loading"
    ></v-progress-linear>

    <v-card-title class="bg-clr" style="padding: 16px !important">
      <v-layout column wrap>
        <div class="headline">Role Management - User Type</div>
        <v-spacer />
        <div class="heading py-1 muted">All roles defined with sub roles</div>
        <v-spacer />
      </v-layout>
      <v-spacer />
      <v-btn
        class="white--text"
        flat
        color="orange darken-1"
        @click.native="add('user-type')"
        :disabled="loading"
      >
        <v-icon>add</v-icon>&nbsp;Add User Type
      </v-btn>
    </v-card-title>
    <!-- <v-card-title primary-title class="bg-clr">
                <v-btn fab flat icon class="nospace" @click.native="goback">
                    <v-icon>arrow_back</v-icon>
                </v-btn>
                <span class="headline">&nbsp;Role Management - User Type</span>
            </v-card-title> -->
    <v-layout row wrap align-center>
      <v-layout row wrap>
        <v-flex xs12 style="text-align: center">
          <div class="heading py-1 muted">
            Roles are shown on the basis of all countries
          </div>
        </v-flex>
        <!-- <v-flex xs2 style="text-align: end;">
          <div>
            <v-btn
              class="white--text"
              flat
              color="orange darken-1"
              @click.native="goTo()"
            >
              <v-icon>add</v-icon>&nbsp;Add Role Subrole
            </v-btn>
          </div>
        </v-flex> -->
      </v-layout>
      <v-flex md12 class="communication">
        <!-- <v-card-title class="bg-clr">
                            <v-btn
                                class="white--text"
                                flat
                                color="orange darken-1"
                                @click.native="add('user-type')"
                            >
                                <v-icon>add</v-icon>&nbsp;Add User Type
                            </v-btn>
                        </v-card-title> -->
        <v-card class="nospace">
          <v-card-text class="nospace">
            <v-data-table
              id="jobsTable"
              hide-actions
              :headers="userTypeheaders"
              :items="userTypeItems"
              style="word-break: break-all"
            >
              <template slot="items" slot-scope="props">
                <td style="width: 20%">{{ props.index + 1 }}</td>
                <td style="width: 20%">
                  <div>{{ props.item.userTypeTitle || N.A }}</div>
                </td>
                <td style="width: 20%">
                  <div>{{ getStatus(props.item.isActive) || N.A }}</div>
                </td>
                <td style="width: 20%">
                  <v-layout>
                    <!-- <v-tooltip
                                                            top
                                                            content-class="tooltip-op"
                                                            color="red darken-1"
                                                        >
                                                            <template slot="activator">
                                                                <v-icon
                                                                    color="red darken-1"
                                                                    flat
                                                                    prepend-icon
                                                                    @click.native="deleteUserType(props.item)"
                                                                    class="userType-actions"
                                                                    title="Delete User Type"
                                                                >delete</v-icon>
                                                            </template>
                                                            <span class="tooltip">Delete User Type</span>
                                            </v-tooltip>-->
                    <v-tooltip
                      top
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          color="orange darken-1"
                          flat
                          prepend-icon
                          @click.stop="dialog = false"
                          @click="showMapping(props.item, 'user-role')"
                          title="Show User Type - Role Mapping"
                          >visibility</v-icon
                        >
                      </template>
                      <span class="tooltip">Show User Type - Role Mapping</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          color="orange darken-1"
                          flat
                          v-permissions="'view-mapping'"
                          v-if="props.item.weight > weight"
                          prepend-icon
                          @click.stop="dialog = false"
                          @click="createMapping(props.item, 'user-role')"
                          class="role-add-actions pl-2"
                          title="Create User Type - Role Mapping"
                          >settings_ethernet</v-icon
                        >
                      </template>
                      <span class="tooltip"
                        >Create User Type - Role Mapping</span
                      >
                    </v-tooltip>
                  </v-layout>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import ErrorBox from "@/components/Common/ErrorBox";
import { rolesList } from "@/constants/datatable-headers.js";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import {
  getAllUserType,
  getAllRoles,
  getAllOperations,
  updateUserType,
  updateRole,
  updateOperation,
} from "../../constants/api-urls";
import Swal from "sweetalert2";
export default {
  mixins: [checkPermissionsMixin],
  created() {
    this.getAllUserType();
    this.getAllRoles();
    this.getAllSubRoles();
    let permissions = JSON.parse(localStorage.getItem("permissions"));
    this.weight = permissions.userType.weight || 0;
  },
  components() {},
  data() {
    return {
      loading: false,
      showScreen: true,
      dialog: false,
      userTypeheaders: rolesList,
      rolesheaders: [
        {
          text: "Sr No",
          sortable: false,
          value: "srno",
          class: "table-header-item",
        },
        {
          text: "Role Name",
          sortable: false,
          class: "table-header-item",
          value: "user-type",
        },
        {
          text: "Status",
          sortable: false,
          class: "table-header-item",
          value: "status",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          class: "table-header-item",
        },
      ],
      subrolesheaders: [
        {
          text: "Sr No",
          sortable: false,
          value: "srno",
          class: "table-header-item",
        },
        {
          text: "Sub Role Name",
          sortable: false,
          class: "table-header-item",
          value: "user-type",
        },
        {
          text: "Status",
          sortable: false,
          class: "table-header-item",
          value: "status",
        },
      ],
      userTypeItems: [],
      rolesItems: [],
      subRolesItems: [],
      x: {
        error: null,
        message: null,
        success: false,
      },
    };
  },

  methods: {
    goback() {
      this.$router.go(-1);
    },
    goTo() {
      this.$router.push(`/roles-add`);
    },
    getStatus(status) {
      if (status) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
    getAllUserType() {
      this.loading = true;
      let url = getAllUserType;
      delete this.axios.defaults.headers.common["token"];

      this.axios.get(this.constants.rbacUrl + url).then(
        (response) => {
          this.userTypeItems = response.data.data;
          const filtered = this.userTypeItems.filter((items) => {
            return !items.isHidden;
          });
          this.userTypeItems = filtered;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Types";
        }
      );
    },
    getAllRoles() {
      this.loading = true;
      let url = getAllRoles;
      delete this.axios.defaults.headers.common["token"];

      this.axios.get(this.constants.rbacUrl + url).then(
        (response) => {
          this.rolesItems = response.data.data;
        },
        (error) => {
          this.x.error = "Failed to Fetch Roles.";
        }
      );
    },
    getAllSubRoles() {
      this.loading = true;
      let url = getAllOperations;
      delete this.axios.defaults.headers.common["token"];
      this.axios.get(this.constants.rbacUrl + url).then(
        (response) => {
          this.subRolesItems = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Sub Roles";
          this.loading = false;
        }
      );
    },
    deleteUserType(userObj) {
      let body = {
        isActive: false,
        isDeleted: true,
        _id: userObj._id,
      };
      this.loading = true;
      let url = updateUserType;
      delete this.axios.defaults.headers.common["token"];

      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.getAllUserType();
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Delete User Type";
          this.loading = false;
        }
      );
    },
    deleteRole(roleObj) {
      let body = {
        isActive: false,
        isDeleted: true,
        _id: roleObj._id,
      };
      this.loading = true;
      let url = updateRole;
      delete this.axios.defaults.headers.common["token"];
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.getAllRoles();
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Delete Role";
          this.loading = false;
        }
      );
    },
    deleteSubRole(roleObj) {
      let body = {
        isActive: false,
        isDeleted: true,
        _id: roleObj._id,
      };
      this.loading = true;
      let url = updateOperation;
      delete this.axios.defaults.headers.common["token"];
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.getAllSubRoles();
          this.loading = faxse;
        },
        (error) => {
          this.x.error = "Failed to Delete Sub Role";
          this.loading = false;
        }
      );
    },

    add(reqType) {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());

          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());

          old.sort();
          roles.sort();

          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            this.$router.push(`/add-roles/${reqType}`);
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    showMapping(obj, mappingType) {
      let id = obj._id;
      let url = "";
      if (mappingType == "user-role") {
        url = `/assignedRoletoUserType/${id}`;
      }
      if (mappingType == "role-sub-role") {
        url = `/assignedSubRoletoRole/${id}`;
      }
      this.loading = true;

      delete this.axios.defaults.headers.common["token"];

      this.axios.get(this.constants.rbacUrl + url).then(
        (response) => {
          this.mappingData = response.data.data;
          this.mappingData.mappingType = mappingType;
          this.dialog = true;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Roles.";
        }
      );
    },
    createMapping(obj, mappingType) {
      this.$router.push(`/mapping/${obj._id}/${mappingType}`);
    },
  },
};
</script>
<style lang="scss">
#userType th,
#userRole th,
#userSubRole th {
  background: orange !important;
  font-weight: bold;
  padding: 0 15px;
}
#userType td,
#userRole td,
#userSubRole td {
  font-size: 16px !important;
  text-align: left;
}
</style>
<style scoped>
.role {
  padding-left: 20px;
}

.roles-heading {
  text-align: left;
  color: orange;
}
.add {
  vertical-align: top;
  color: orange;
  font-size: 16px;
}
.theme--light.v-icon {
  cursor: pointer;
}
.scroll_class {
  position: relative;
  overflow: scroll;
  height: 450px;
}
</style>
<style>
.theme--light.v-icon {
  cursor: pointer;
}
</style>
